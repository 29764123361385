import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
// import { AuthService } from '../auth.service';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  // intercept(req: HttpRequest<any>, next: HttpHandler) {
  //   // Encode app id and app key.
  //   const authToken = 'Basic ' + btoa(`${environment.app.id}:${environment.app.key}`);

  //   // Clone the request and set the new header in one step.
  //   const authReq = req.clone({ setHeaders: { Authorization: authToken } });

  //   // send cloned request with header to the next handler.
  //   return next.handle(authReq);
  // }
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // Encode app id and app key.

  console.log(sessionStorage.getItem("lanToken"))
if(sessionStorage.getItem('sessionInfo')){

  const authToken = 'Bearer ' + JSON.parse(sessionStorage.getItem('sessionInfo')).token;
  const authReq = req.clone({ setHeaders: { Authorization: authToken,lang:sessionStorage.getItem("lanToken")} });

  // send cloned request with header to the next handler.
  return next.handle(authReq);
}else{

  const authToken = 'Basic ' + btoa(`${environment.app.id}:${environment.app.key}`);
  const authReq = req.clone({ setHeaders: { Authorization: authToken,lang:sessionStorage.getItem("lanToken")} });

  // send cloned request with header to the next handler.
  return next.handle(authReq);

}



   // const authToken = 'Bearer ' + sessionStorage.getItem('TokenInfo');


    // Clone the request and set the new header in one step.
    // const authReq = req.clone({ setHeaders: { Authorization: authToken } });

    // send cloned request with header to the next handler.
    // return next.handle(authReq);
  }
}
