// import { SignaturepadModule } from '@ng-plus/signature-pad';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
//import 'pace';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { routing } from './app.routing';
import { AppConfig } from './app.config';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
// import { ErrorComponent } from './pages/error/error.component';
import { ToastrModule } from 'ngx-toastr';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OrderModule } from 'ngx-order-pipe';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as exporting from 'highcharts/modules/exporting.src';
import * as exportData from 'highcharts/modules/export-data.js';
import { Injectable } from '@angular/core';
import { LoaderComponent } from './loader/loader.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';

// import { DataTablesModule } from 'angular-datatables';

import { LoaderInterceptorService } from './services/http-interceptors/loader-interceptor.service';
// import { SuccessComponent } from './pages/success/success.component';
// import { NamelbrPipe } from './pipe/namelbr.pipe';
import { PhoneNoFormatPipe } from './pipe/phone-format';

// import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { DATA} from './pages/students/students.component'
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


// import { InsuranceComponent } from './insurance/insurance.component';
@Injectable()
export class AuthGuard {
  constructor(private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (typeof (Storage) !== "undefined") {
      if (sessionStorage.getItem('sessionInfo')) {
        const expectedRoles = route.data.expectedRoles;
        const role = JSON.parse(sessionStorage.getItem('sessionInfo')).tenantType;
        if (expectedRoles.indexOf(role) > -1 || expectedRoles.indexOf(role) > -1)
          return Promise.resolve(true);
        else {
          this.router.navigate(['/pages/dashboard']);
          return Promise.resolve(true);
        }
      }
    }
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return Promise.resolve(false);
  }
}
@NgModule({
   declarations: [
      AppComponent,
      // ErrorComponent,
      LoaderComponent,
      // SuccessComponent,
      PhoneNoFormatPipe,
      // Nl2brPipe,

      // InsuranceComponent
   ],
   imports: [
      BrowserModule,
      ReactiveFormsModule,
      FormsModule,
      // DataTablesModule,

      BrowserAnimationsModule,

      AgmCoreModule.forRoot({
        apiKey: 'AIzaSyCx_ZaqWDu6leZ7ffeIz5sG9qrN5s4KFF0',
        libraries: ["places"]
      }),
    ToastrModule.forRoot(),
    ConfirmationPopoverModule.forRoot({confirmButtonType:'danger'}),
    routing,
    SharedModule,
    ChartModule,
    OrderModule,
    HttpClientModule,
    HttpModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    LoggerModule.forRoot({
      serverLoggingUrl: '',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF
    }),
    // GoogleTagManagerModule.forRoot({
    //   id: 'GTM-TZFWJ29T',
    //   // gtm_auth: YOUR_GTM_AUTH,
    //   // gtm_preview: YOUR_GTM_ENV
    // })
  ],

  providers: [GoogleMapsAPIWrapper,PhoneNoFormatPipe, AppConfig, AuthGuard, {provide: 'googleTagManagerId', useValue: 'AW-11256566770'},GoogleTagManagerService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }


